<template>
  <div>
    <div class="personal_center">
      <section class="left-side">
        <ul class="menu-list">
          <li v-for="(item,index) in $t(`personalCenter.slideBar`)" :key="index"
            :class="{active:$route.path==item.path}" @click="changeMenu(item)">
            {{item.text}}
          </li>
        </ul>
      </section>
      <section class="right-side">
        <router-view></router-view>
      </section>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/common/xfooter'
export default {
  name: "personalCenter",
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    footers
  },
  methods: {
    changeMenu (item) {
      this.$router.push(item.path)
    },
  },
}
</script>

<style scoped lang="less">
.personal_center {
  font-size: 0;
  text-align: center;
  padding: 110px 0 20px;
  background: #f2f2f2;
  min-height: calc(100vh - 199px);
  .left-side,
  .right-side {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    text-align: left;
  }
  position: relative;
  .left-side {
    width: 146px;
    background-color: #ffffff;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    position: absolute;
    z-index: 1;
    .menu-list {
      padding-top: 40px;
      li {
        height: 30px;
        line-height: 30px;
        margin-bottom: 20px;
        text-align: center;
        color: #717171;
        letter-spacing: 0px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: @blue;
        }
        &.active {
          background-color: #f7f9fd;
          color: @blue;
          &:before {
            content: "";
            width: 4px;
            height: 30px;
            position: absolute;
            left: 0;
            background: @blue;
          }
        }
      }
    }
  }
  .right-side {
    max-width: 1400px;
    width: 90%;
    padding-left: 157px;
    position: relative;
    min-height: 66px;
    box-sizing: border-box;
  }
}
</style>
